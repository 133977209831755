import { render, staticRenderFns } from "./NewCommission.vue?vue&type=template&id=b326b964&scoped=true&"
import script from "./NewCommission.vue?vue&type=script&lang=js&"
export * from "./NewCommission.vue?vue&type=script&lang=js&"
import style0 from "./NewCommission.vue?vue&type=style&index=0&id=b326b964&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b326b964",
  null
  
)

export default component.exports