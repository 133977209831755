<template>
    <Tickets/>
</template>
<script>
import Tickets from '@/modules/Ticket/Tickets'

export default {
    name: 'TicketsPage',
    components: { Tickets },

}
</script>


