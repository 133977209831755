<template>
    <Dashboard/>
</template>
<script>
import Dashboard from '@/modules/MiniGrid/Dashboard.vue'

export default {
    name: 'MiniGridDashboardPage',
    components: {
        Dashboard
    },
}
</script>


