<template>
    <ConnectionTypesList/>
</template>

<script>
import ConnectionTypesList from '@/modules/ConnectionTypes/ConnectionTypesList'
export default {
    name: 'ConnectionTypesPage',
    components: { ConnectionTypesList }
}
</script>