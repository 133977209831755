<template>
    <Register/>
</template>


<script>
import Register from '@/modules/Register/Register.vue'
export default {
    name: 'RegisterPage',
    components: { Register },
}
</script>