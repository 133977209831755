<template>
    <SolarHomeSystems/>
</template>

<script>
import SolarHomeSystems from '@/modules/SolarHomeSystem/SolarHomeSystems.vue'

export default {
    name: 'SolarHomeSystemsPage',
    components: { SolarHomeSystems }
}
</script>

<style scoped>

</style>