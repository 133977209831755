import { render, staticRenderFns } from "./SmsBody.vue?vue&type=template&id=67facd85&scoped=true&"
import script from "./SmsBody.vue?vue&type=script&lang=js&"
export * from "./SmsBody.vue?vue&type=script&lang=js&"
import style0 from "./SmsBody.vue?vue&type=style&index=0&id=67facd85&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67facd85",
  null
  
)

export default component.exports