<template>
    <TargetList/>
</template>

<script>
import TargetList from '@/modules/Target/TargetList'

export default {
    name: 'TargetsPage',
    components: { TargetList }
}
</script>
