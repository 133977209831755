<template>
    <AgentCommissionList/>
</template>

<script>
import AgentCommissionList from '@/modules/Agent/Commission/AgentCommissionList.vue'

export default {
    name: 'AgentCommissionsPage',
    components: { AgentCommissionList }
}
</script>