<template>
  <Transaction/>
</template>

<script>
import Transaction from '@/modules/Transactions/Transaction.vue'
export default {
    name: 'TransactionPage',
    components: { Transaction },
}
</script>


