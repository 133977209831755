<template>
    <Meters/>
</template>
<script>
import Meters from '@/modules/Meter/Meters'

export default {
    name: 'MetersPage',
    components: { Meters }
}
</script>