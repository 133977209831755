<template>
    <NewSms/>
</template>

<script>
import NewSms from '@/modules/Sms/NewSms'

export default {
    name: 'NewSmsPage',
    components: { NewSms }
}
</script>

<style scoped>

</style>