<template>
    <ConnectionGroupsList/>
</template>

<script>
import ConnectionGroupsList from '@/modules/ConnectionGroups/ConnectionGroupsList'

export default {
    name: 'ConnectionGroupsPage',
    components: { ConnectionGroupsList }
}
</script>