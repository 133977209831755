<template>
    <Assets/>
</template>

<script>
import Assets from '@/modules/Assets/Assets'
export default {
    name: 'AppliancesPage',
    components: { Assets },
}
</script>