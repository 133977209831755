import { render, staticRenderFns } from "./SoldApplianceDetail.vue?vue&type=template&id=199f74dc&scoped=true&"
import script from "./SoldApplianceDetail.vue?vue&type=script&lang=js&"
export * from "./SoldApplianceDetail.vue?vue&type=script&lang=js&"
import style0 from "./SoldApplianceDetail.vue?vue&type=style&index=0&id=199f74dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199f74dc",
  null
  
)

export default component.exports