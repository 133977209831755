<template>
    <Tariff/>
</template>
<script>
import Tariff from '@/modules/Tariff/Tariff.vue'
export default {
    name: 'TariffPage',
    components: { Tariff }
}
</script>

