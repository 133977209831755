<template>
    <Agent/>
</template>

<script>
import Agent from '@/modules/Agent/Agent'
export default {
    name: 'AgentPage',
    components: { Agent }
}
</script>