<template>
    <Dashboard/>
</template>
<script>
import Dashboard from '@/modules/Dashboard/Dashboard'

export default {
    name: 'DashboardPage',
    components: { Dashboard },
}
</script>

