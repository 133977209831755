<template>
<NewConnectionType/>
</template>

<script>
import NewConnectionType from '@/modules/ConnectionTypes/NewConnectionType'

export default {
    name: 'NewConnectionTypePage',
    components: { NewConnectionType }
}
</script>

<style scoped>

</style>