<template>
    <Maintenance/>
</template>

<script>
import Maintenance from '@/modules/Maintenance/Maintenance'

export default {
    name: 'MaintenancePage',
    components: { Maintenance }
}
</script>