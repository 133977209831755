<template>
    <List/>
</template>

<script>
import List from '@/modules/Sms/List'

export default {
    name: 'SMSPage',
    components: { List }
}
</script>