<template>
    <MeterTypes/>
</template>

<script>
import MeterTypes from '@/modules/MeterType/MeterTypes'

export default {
    name: 'MeterTypesPage',
    components: { MeterTypes }
}
</script>