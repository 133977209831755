<template>
    <AddVillage/>
</template>

<script>
import AddVillage from '@/modules/Village/AddVillage'

export default {
    name: 'NewVillagePage',
    components: { AddVillage }
}
</script>